
<template>
  <div class="page">
    <div class="container">
      <div class="btn-group">
        <div class="float-wrap">
          <div class="float-logo-wrap">
            <img
              class="float-logo"
              src="../assets/img/img_share_logo_s.png"
              alt=""
            />
          </div>
          <div class="float-text">打开App</div>
        </div>
        <WxOpen> </WxOpen>
      </div>
    </div>
  </div>
</template>
<script>

// <!-- :extinfo="dataStr" -->
import { Dialog } from "vant";
import wx from "weixin-js-sdk";
import WxOpen from "./xinjiaren/components/WxOpen.vue";
export default {
  head() {
    return {
      title: "广汽三菱M-SPACE",
    };
  },
  components: {
    // Catalogue,
    // Evaluate,
    // Synopsis,
    WxOpen,
  },
  data() {
    return {
      wechatState: false, // 是否显示微信打开app功能按钮
      openAppState: false, //  显示打开app 的按钮
      dataStr: "",
    };
  },
  methods: {
    closeTip() {},
    openUrl() {
      this.judgePhoneType();
    },
    judgePhoneType() {
      var u = navigator.userAgent;
      var isWeixin = u.toLowerCase().indexOf("micromessenger") !== -1; // 微信内
      var isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android终端
      var isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      // 微信内
      if (isWeixin) {
        Dialog.alert({ message: "微信浏览器" });
      } else {
        if (isAndroid) this.openInstallHandle("android"); //android端
        if (isIOS) this.openInstallHandle("ios"); //ios端
      }
    },
    launch() {},

    openInstallHandle() {},

    judgeNavigator() {
      //   // 微信版本号大于 7.0.12 开放标签才可进行 唤醒 app 跳转
      //   const wechatInfo = window.navigator.userAgent.match(
      //     /MicroMessenger\/([\d\.]+)/i
      //   );
      //   if (!wechatInfo) return;
      //   let judgewechat = wechatInfo[1].split(".");
      //   let flag = false;
      //   //   Dialog.alert({ message: "调用结果" + JSON.stringify(wechatInfo) });
      //   if (judgewechat[0] >= 7) {
      //     if (judgewechat[1] >= 0) {
      //       if (judgewechat[2] >= 12) {
      //         this.wechatState = true;
      //         console.log("当前符合 h5 打开指定app");
      //         flag = true;
      //       }
      //     }
      //   }
      //   if (!flag) Dialog.alert({ message: "请升级当前微信版本" });
    },

    handleError(e) {
      console.log("error", e, e.detail);
      // 跳到下载download.html页面
      Dialog.alert({ message: "error:" + JSON.stringify(e) });
    },
    handleReady(e) {
      console.log("ready", e);
      Dialog.alert({ message: "ready:" + JSON.stringify(e) });
    },
    handleLaunch(e) {
      console.log("success", e, e.detail);
      Dialog.alert({ message: "success:" + JSON.stringify(e) });
    },

    launchBtnInit() {
      // 获取 homelist 组件传递过来的打开app的 显示状态
      var btn = document.getElementById("launch-btn");
      if (!btn) return;
      console.log("launchBtnInit");
      btn.addEventListener("launch", function (e) {
        console.log("success", e, e.detail);
        Dialog.alert({ message: "success:" + JSON.stringify(e.detail) });
      });
      btn.addEventListener("error", function (e) {
        console.log("error", e, e.detail);
        // if (e.isTrusted == false) {
        //   // alert("跳转失败")
        //   window.open("https://www.baidu.com");
        // }
        Dialog.alert({ message: "error:" + JSON.stringify(e.detail) });
      });
      document.addEventListener("WeixinOpenTagsError", function (e) {
        console.error(e.detail.errMsg); // 无法使用开放标签的错误原因，需回退兼容。仅无法使用开放标签，JS-SDK其他功能不受影响
      });
    },

    wechatInit() {
      this.$get("wechat/getConfig", {
        url: window.location.href.split("#")[0],
      })
        .then((res) => {
          if (res) {
            wx.config({
              // eslint-disable-line
              debug: true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印
              appId: res.appId, // 必填，公众号的唯一标识
              timestamp: res.timestamp, // 必填，生成签名的时间戳
              nonceStr: res.nonceStr, // 必填，生成签名的随机串
              signature: res.signature, // 必填，签名
              jsApiList: [
                "getLocalImgData",
                "chooseImage",
                "previewImage",
                "uploadImage",
                "openLocation",
                "getLocation",
                "scanQRCode",
                "chooseImage",
                "previewImage",
              ], // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
              openTagList: ["wx-open-launch-app"], // 获取开放标签权限
            });
          }
        })
        .catch(() => {});
      /* eslint-disable */

      var that = this;
      wx.ready(function () {
        console.log("ready");
        that.launchBtnInit(); // 外部浏览器唤起app
      });
      wx.error(function (res) {
        // config信息验证失败会执行 error 函数，如签名过期导致验证失败，具体错误信息可以打开 config 的debug模式查看，也可以在返回的 res 参数中查看，对于 SPA 可以在这里更新签名
        console.log("error", res);
      });
    },
    wechatConfig(config) {
      let that = this;
      wx.config({
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: config.appId, // 必填，公众号的唯一标识
        timestamp: config.timestamp, // 必填，生成签名的时间戳
        nonceStr: config.nonceStr, // 必填，生成签名的随机串
        signature: config.signature, // 必填，签名，见附录1
        jsApiList: [
          "onMenuShareAppMessage",
          "onMenuShareTimeline",
          "chooseImage",
          "previewImage",
        ], // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
        openTagList: ["wx-open-launch-app"], // 获取开放标签权限
      });
      wx.error(function (err) {
        console.log("错误：", err);
        Dialog.alert({ message: "错误:" + JSON.stringify(err) });
      });
      wx.ready(function () {
        // 分享朋友圈
        console.log("微信签名成功");
        Dialog.alert({ message: "微信签名成功" });
        that.launchBtnInit(); // 外部浏览器唤起app
        that.wechatState = true;
        wx.onMenuShareTimeline({
          title: "广汽三菱M-SPACE", // 分享标题
          link: "", // 分享链接
          imgUrl: window.location.origin + "/static/img/appLogo.jpg", // 分享图标
          success: () => {
            // 用户确认分享后执行的回调函数
            console.log("分享成功");
            Dialog.alert({ message: "分享成功" });
          },
          cancel: () => {},
        });
        let appLogo = window.location.origin + "/static/img/appLogo.jpg"; // app图标
        // 分享朋友
        wx.onMenuShareAppMessage({
          title: "广汽三菱M-SPACE", // 分享标题
          desc: "分享内容", // 分享描述
          link: "", // 分享链接
          imgUrl: window.location.origin + "/static/img/appLogo.jpg", // 分享图标
          type: "", // 分享类型,music、video或link，不填默认为link
          dataUrl: "", // 如果type是music或video，则要提供数据链接，默认为空
          success: () => {
            // 用户确认分享后执行的回调函数
            Dialog.alert({ message: "分享成功" });
          },
          cancel: function cancel() {
            // 用户取消分享后执行的回调函数
          },
        });
      });
    },
  },

  computed: {},

  mounted() {
    this.judgeNavigator();
    this.wechatInit(); // 微信标签唤起app
    let data = {
      uri: `gimmc://www.gimmccc.com/common/webview?url=${encodeURIComponent(
        window.location.origin + "/info/detail?id=48&goindex=1"
      )}`,
    };
    this.dataStr = JSON.stringify(data);
    // this.$nextTick(() => {
    //   this.$refs.openaRef.commitChildHandle({
    //     title: "广汽三菱M-SPACE", // 分享标题
    //     desc: "分享内容~~~~~", // 分享描述
    //     link: "", // 分享链接
    //     imgUrl: "", // 分享图标
    //   });
    // });
  },
  created() {},
  onShow() {},
};
</script>
<style  scoped>
.page {
  overflow: auto;
  height: 100vh;
  background: #fff;
}
.container {
  padding-top: 80px;
}
.result-status {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 128px;
  margin-top: 113px;
}
.btn-group {
  position: fixed;
  right: 0;
  top: 14%;
  height: 56px;
  background: rgba(62, 129, 228, 0.9);
  border-radius: 100px 0px 0px 100px;
  overflow: hidden;
}

.float-wrap {
  position: relative;
  line-height: 56px;
  font-size: 10px;
  color: #ffffff;
  padding: 0.5px 20px 0 2px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.float-logo-wrap {
  width: 52px;
  height: 52px;
  margin: 0 10px 0 0;
  cursor: pointer;
  border-radius: 50%;
  background: #ffffff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.float-logo {
  width: 48px;
  height: 48px;
  border-radius: 50%;
}
.float-text {
  font-size: 20px;
}
.search {
  color: #2b303c;
}

</style>